<template>
  <div>
    <associatedOption
        :fieldAttributes="{
        name: fieldAttributes.name,
        options:operators,
        ...fieldAttributes,
      }"
        :type="fieldAttributes.type"
        v-on="$listeners"
        :value="value"
        :disabled="checkReadOnly"
        :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  components: { associatedOption },
  props: ["result", "fieldAttributes", "checkReadOnly", "value"],
  computed: {
   operators() {
      var operator =  options.sipCallCheck
      if(this.result.audioQuality===true){
        operator = options.sipCallCheckAudioQuality
      }else {
         operator =  options.sipCallCheck
       }
      return operator;
    },
  },
};
</script>